import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { IRegisteredSession } from "../../interfaces/registered-session.interface";
import { map, Observable, of, ReplaySubject, switchMap } from "rxjs";
import { IPremiumEntry, ISaleProduct } from "../../interfaces/premium-entry.interface";
import { FidelityLevel } from "../../enums/fidelity-level.enum";
import { ICasinoInjApi } from "../casino-inj-api/interfaces/ICasinoInjApi";
import { ISwiftSaleApi } from "./interfaces/ISwiftSaleApi";

@Injectable({
  providedIn: 'root'
})
export class SwiftSalesService {


  private baseUrl = environment.swiftSaleApiUrl;
  public currentSession$: ReplaySubject<IRegisteredSession> = new ReplaySubject<IRegisteredSession>();

  constructor(private http: HttpClient) {
  }

  getCurrentSession() {
    return this.http.get<IRegisteredSession[]>(this.baseUrl + '/register-sessions/current');
  }

  premiumEntry(premiumEntry: IPremiumEntry) {
    return this.http.post(this.baseUrl + '/register-sessions/sales/by-product-code', premiumEntry);
  }

  getProductsByFidelityLevel(fidelityLevel: FidelityLevel): Observable<ISaleProduct[]> {
    return this.http.get<ISaleProduct[]>(this.baseUrl + '/products').pipe(
      switchMap((result: ISaleProduct[]) => {
        let products: ISaleProduct[] = [];
        switch (fidelityLevel) {
          case FidelityLevel.Gold:
            products = result.filter(product => product.name.includes('OR'));
            break;
          case FidelityLevel.Black:
            products = result.filter(product => product.name.includes('NOIR'));
            break;
          case FidelityLevel.Ultimate:
            products = result.filter(product => product.name.includes('ULTIME'));
            break;
        }
        return of(products);
      })
    );
  }


  getParameters() {
    return this.http.get(this.baseUrl + '/system').pipe(map((res: any) => {
        let result: ISwiftSaleApi = {
          commitHash: res.version.commit,
          version: res.version
        };

        return result;
      })
    )
  }
}
