import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map } from "rxjs";
import { IWalletApi } from "../wallet-api/interfaces/IWalletApi";


@Injectable({
  providedIn: 'root'
})
export class AsosApiService {


  constructor(public http: HttpClient) { }

  private urlBase = environment.casinoAsosApiUrl;

  getParameters() {
    return this.http.get(this.urlBase + '/system').pipe(map((res: any) => {
      let result: IWalletApi = {
        commitHash: res.version.commit,
        version: res.version.tag
      };

      return result;
    })
    )
  }
}
