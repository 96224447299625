import { Injectable } from '@angular/core';
import { map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ICasinoLoyaltyApi } from "./interfaces/ICasinoLoyaltyApi";

@Injectable({
  providedIn: 'root'
})
export class CasinoLoyaltyApiService {

  private baseUrl = environment.casinoLoyaltyApiUrl;

  constructor(private http: HttpClient) { }

  getParameters() {
    return this.http.get(this.baseUrl + '/system').pipe(map((res: any) => {
        let result: ICasinoLoyaltyApi = {
          commitHash: res.version.commit,
          version: res.version.tag
        };

        return result;
      })
    )
  }
}
