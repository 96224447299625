import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IEntryService } from "../../../../services/czam-api/entry.service";
import { ICustomerEntryDto, IOfflineEntryDto } from "../../../../services/czam-api/dtos/customer-entry-dto";
import { IClientEntryDto } from "../../../../dtos/client-entry-dto";
import {
  ICustomerDeniedEntryDto,
  IOfflineDeniedEntryDto
} from "../../../../services/czam-api/dtos/customer-denied-entry-dto";

let today = new Date();
let birthDate = new Date();
birthDate.setFullYear(1980);

@Injectable({
  providedIn: 'root'
})
export class FakeEntryService implements IEntryService {

  constructor() { }

  saveCustomerEntry(customerEntry: ICustomerEntryDto): {} {
    console.log('saveCustomerEntry called');
    return of();
  }

  saveClientEntry(clientEntry: IClientEntryDto): {} {
    console.log('saveClientEntry called');
    return of();
  }

  saveDeniedEntry(deniedEntry: ICustomerDeniedEntryDto): {} {
    console.log('saveDeniedEntry called');
    return of();
  }

  counter(casinoCode: string): {} {
    return of();
  }

  saveOfflineDeniedEntries(deniedOfflineEntries: IOfflineDeniedEntryDto[]): Observable<boolean> {
    return of();
  }

  saveOfflineEntries(customerOfflineEntries: IOfflineEntryDto[]): Observable<boolean> {
    return of();
  }

  getClientLastVisitInCasino(clientId: number, casinoDay: Date, casinoCode: string): Observable<IClientEntryDto | null> {
    return of();
  }
  }
