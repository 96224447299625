<div id="homePage" class="homePage">
  <div id="cardsAndTitle" class="cards-and-title">
    <div id="title">
      <span class="title">{{'MENU.HOMEPAGE.COMPANY_NAME' | translate}}</span>
    </div>
    <div id="cards" class="cards">
      <ng-container *isGranted="'CJR|VDI|MCD|DIR_JEUX|DIR_CASINO|MARKETING|ADMIN'">
        <div class="card-container no-display-on-mobile" *ngIf="authService.hasCasinoRole">
          <a id="check-in-card" href="/czam/check-in">
            <div id="vdi-card" class="card">
              <div id="vdi-logo">
                <img src="assets/icons/vdi.svg">
              </div>
              <div class="cardText">
                <div class="itemTitle">
                  <span>{{'MENU.HOMEPAGE.VDI' | translate}} </span>
                </div>
                <div class="itemLabel">
                  <span>{{'MENU.HOMEPAGE.CASINO' | translate}}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
      <ng-container *isGranted="'CJR|VDI|MCD|DIR_JEUX|DIR_CASINO|MARKETING|ADMIN'">
        <div class="card-container" *ngIf="authService.hasCasinoRole">
          <a id="client-creation-card" [attr.href]="isMobile ?  '/czam/add-member' : '/czam/client-creation-dashboard'">
            <div id="membership-card" class="card">
              <div id="membership-logo">
                <img src="assets/icons/membership.svg">
              </div>
              <div class="cardText">
                <div class="itemTitle">
                  <span>{{'MENU.HOMEPAGE.MEMBERSHIP' | translate}}</span>
                </div>
                <div class="itemLabel">
                  <span>{{'MENU.HOMEPAGE.CASINO' | translate}}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>

      <div class="card-container" *ngIf="authService.hasCasinoRole">
        <a id="client-hub-card" href="/client-hub/customer-extended" (click)="resetClientHubLocalStorage()">
          <div id="costumerExtended-casino-card" class="card">
            <div id="costumerExtended-casino-logo">
              <img src="assets/icons/customer-extended-casino.svg">
            </div>
            <div class="cardText">
              <div class="itemTitle">
                <span>{{'MENU.HOMEPAGE.CUSTOMER_EXTENDED' | translate}}</span>
              </div>
              <div class="itemLabel">
                <span>{{'MENU.HOMEPAGE.CASINO' | translate}}</span>
              </div>
            </div>
          </div>
        </a>
      </div>

      <ng-container *isFeatureActive="'CASINO_REPORTS'">
        <ng-container *isGranted="'MCD|DIR_JEUX|DIR_CASINO|MARKETING|ADMIN'"  >
          <div *ngIf="authService.hasCasinoRole && !isMobile" class="card-container">
            <a id="casino-reports-link" href="/casino-reports/reports">
              <div id="casino-reports-card" class="card">
                <div id="casino-reports-logo">
                  <img src="assets/icons/extraction.svg">
                </div>
                <div class="cardText">
                  <div class="itemTitle">
                    <span>{{ 'MENU.HOMEPAGE.REPORTS' | translate }}</span>
                  </div>
                  <div class="itemLabel">
                    <span>{{ 'MENU.HOMEPAGE.CASINO' | translate }}</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ng-container>
      </ng-container>

      <div class="card-container">
        <a href="/hotel-crm/">
          <div id="costumerExtended-hotel-card" class="card">
            <div id="costumerExtended-hotel-logo">
              <img src="assets/icons/customer-extended-hotel.svg">
            </div>
            <div class="cardText">
              <div class="itemTitle">
                <span>{{'MENU.HOMEPAGE.CUSTOMER_EXTENDED' | translate}}</span>
              </div>
              <div class="itemLabel">
                <span>{{'MENU.HOMEPAGE.HOTEL' | translate}}</span>
              </div>
            </div>
          </div>
        </a>
      </div>

      <ng-container *isGranted="'RCPT|ADMIN'">
        <div class="card-container">
          <a href="/hotel-reservation/">
            <div id="hotel-reservation-card" class="card">
              <div id="hotel-reservation-logo">
                <img src="assets/icons/hotel-reservation.svg">
              </div>
              <div class="cardText">
                <div class="itemTitle">
                  <span>{{'MENU.HOMEPAGE.HOTEL_RESERVATION' | translate}}</span>
                </div>
                <div class="itemLabel">
                  <span>{{'MENU.HOMEPAGE.HOTEL' | translate}}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>

    </div>
  </div>

  <div id="parameters" class="parameters" *ngIf="authService.hasCasinoRole && !isMobile">
    <ng-container *isFeatureActive="'CHECK_IN_MGR'">
      <div class="paramContainer" *isGranted="'CJR|MCD|DIR_CASINO|DIR_JEUX|ADMIN'">
        <a href="/casino-restrictions-inj/restrictions-inj">
          <div id="restrictions" class="paramItem">
            <div id="restrictionTitle" class="itemTitle">
              <span>{{'MENU.HOMEPAGE.RESTRICTIONS' | translate}}</span>
            </div>
            <div id="restrictionLabel" class="itemLabel">
              <span>{{'MENU.HOMEPAGE.CASINO' | translate}}</span>
            </div>
          </div>
        </a>
      </div>
    </ng-container>
    <ng-container *isFeatureActive="'CASINO_ADMIN'">
      <div class="paramContainer" *isGranted="'DIR_CASINO|DIR_JEUX|ADMIN'">
        <a href="/casino-admin/">
          <div id="admin" class="paramItem">
            <div id="adminTitle" class="itemTitle">
              <span>{{'MENU.HOMEPAGE.ADMIN' | translate}}</span>
            </div>
            <div id="adminLabel" class="itemLabel">
              <span>{{'MENU.HOMEPAGE.CASINO' | translate}}</span>
            </div>
          </div>
        </a>
      </div>
    </ng-container>
  </div>

  <div id="version">
    <a routerLink="/version">
      <span>Version&nbsp;{{version}}</span>
    </a>
  </div>
</div>
