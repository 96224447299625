import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { url } from 'inspector';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {


  getPathFromUrl(url: string): string {
    try {
      // Check if the URL starts with '/', if not, add it
      if (url.startsWith('/')) {

        // Add a dummy base URL to ensure the URL can be parsed
        const dummyBaseUrl = 'http://dummy.com';
        url = dummyBaseUrl + url;
      }

      const fullUrl = new URL(url);

      let path = fullUrl.pathname;


      // Define manual regex patterns for matching and replacing :id segments
      const regexMap: { pattern: RegExp; replacement: string }[] = [
        { pattern: /\/czam-api\/clients\/card\/[A-Za-z0-9_-]+/, replacement: '/czam-api/clients/card/:rfid' },
        { pattern: /\/czam-api\/clients\/casinos\/[A-Za-z0-9_-]+\/players\/[A-Za-z0-9_-]+\/state/, replacement: '/czam-api/clients/casinos/:casinoCode/players/:id/state' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+/, replacement: '/czam-api/clients/:id' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/term-of-use\/signature/, replacement: '/czam-api/clients/:id/term-of-use/signature' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/cnil-documents/, replacement: '/czam-api/clients/:id/cnil-documents' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/signature\/status/, replacement: '/czam-api/clients/:id/signature/status' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/consent-audit-trail/, replacement: '/czam-api/clients/:id/consent-audit-trail' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/client-data-reviews/, replacement: '/czam-api/clients/:id/client-data-reviews' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/identity-document\/status/, replacement: '/czam-api/clients/:id/identity-document/status' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/picture/, replacement: '/czam-api/clients/:id/picture' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/identity-document/, replacement: '/czam-api/clients/:id/identity-document' },
        { pattern: /\/czam-api\/clients\/[A-Za-z0-9_-]+\/cnil-document\/status/, replacement: '/czam-api/clients/:id/cnil-document/status' },
        { pattern: /\/ocm-connect-api\/casinos\/[A-Za-z]+\/clients\/[A-Za-z0-9_-]+\/slot-session/, replacement: '/ocm-connect-api/casinos/:casinoCode/clients/:id/slot-session' },
        { pattern: /\/casino-loyalty-api\/customers\/[0-9]+/, replacement: '/casino-loyalty-api/customers/:id' },
      ];

      // Apply regex replacements
      for (const { pattern, replacement } of regexMap) {
        if (pattern.test(path)) {
          path = path.replace(pattern, replacement);
          break;
        }
      }

      // Extract and return the pathname and search (query params)
      return path;

    } catch (e) {
      // If there's an error parsing the URL, return the original URL
      console.error('Error parsing URL:', e);
      return url;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (Sentry.isInitialized() === false) return next.handle(req);

    // Create a new URL object
    let path = this.getPathFromUrl(req.url);

    return Sentry.startSpanManual({ name: `${req.method} ${path}`, op: 'http.client', onlyIfParent: false }, (span) => {

      // Debug logging
      /*
      let currentScope = Sentry.getCurrentScope();
      let rootSpan = Sentry.getRootSpan(span);

      console.log(`Call: ${req.url}, root span id: ${JSON.stringify(rootSpan)}`);
      console.log(`Current trace id: ${currentTraceId}`);
      */

      // Clone the request and add the sentry-trace header if traceId is present
      let modifiedReq = req;
      if (span && !req.url.startsWith('http://127.0.0.1:5000')) {
        let baggageHeader = Sentry.spanToBaggageHeader(span);
        let traceHeader = Sentry.spanToTraceHeader(span);

        modifiedReq = req.clone({
          setHeaders: {
            "Sentry-Trace": traceHeader,
            "Baggage": baggageHeader ?? ""
          }
        });
      }

      return next.handle(modifiedReq).pipe(
        tap((res: HttpEvent<any>) => {


          if (res.type === HttpEventType.Response) {

            const parsedURL = new URL(req.url, location.origin);
            span.setAttribute("http.request.method", req.method);
            span.setAttribute("http.request.full_url", req.url);
            span.setAttribute("server.address", parsedURL.hostname);
            span.setAttribute("server.port", parsedURL.port || undefined);
            span.setAttribute("http.response.status_code", res.status);
            span.setAttribute(
              "http.response_content_length",
              Number(res.headers.get("content-length")),
            );

            // A good place to set other span attributes
            span.end();

          }
        })
      );
    });
  }
}
