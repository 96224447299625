import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap, catchError, of, Subject } from 'rxjs';
import moment from "moment";

const MAX_IMAGE_SIZE_IN_BYTES = 4 * 1024 * 1024;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  showLoaderIfNeeded(obs: Observable<any>, timeout: number, startLoader: Function, stopLoader: Function) {
    var timer = setTimeout(() => { startLoader() }, timeout);
    return obs.pipe(tap(
      {
        next: (a) => {
          clearTimeout(timer);
          stopLoader();
        },
        error: (a) => {
          clearTimeout(timer);
          stopLoader();
        }
      }
    ));
  }

  showLoaderIfNeededFromPromise(promise: Promise<any>, timeout: number, startLoader: Function, stopLoader: Function) {
    const timer = setTimeout(() => { startLoader() }, timeout);

    return promise
      .then((result) => {
        clearTimeout(timer);
        stopLoader();
        return result;
      })
      .catch((error) => {
        clearTimeout(timer);
        stopLoader();
        throw error;
      });
  }

  getImageAsBase64ObjectUrl(url: any): Observable<string | null> {
    return this.http.get(url, { responseType: "arraybuffer" })
      .pipe(map(g => {
        return g ? URL.createObjectURL(new Blob([g])) : null;
      }), catchError(err => {
        return of(null);
      }));
  }

  getImageAsBlob(url: string): Observable<Blob | null> {
    return this.http.get(url, { responseType: "arraybuffer" })
      .pipe(map(g => {
        return new Blob([g]);
      }), catchError(err => {
        return of(null);
      }));
  }

  isMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent));
  }


  /**
   * Take a string date of format dd-mm-yyyy or dd/mm/yyyy or yyyy-mm-dd or yyyy/mm-dd and convert it to yyyy-mm-dd
   * @param date
   * @returns
   */
  toyyyymmdd(date: string | null | undefined | Date): string | null {

    if (!date) {
      return null
    }

    if (date instanceof Date) {
      return moment(date).format('YYYY-MM-DD');
    }

    const dd_mm_yyyy_regex = /^(\d{2})[\/\-](\d{2})[\/\-](\d{4})$/;
    const yyyy_mm_dd_regex = /^(\d{4})[\/\-](\d{2})[\/\-](\d{2})$/;
    let day: number = 1, month: number = 1, year: number = 1901;

    if (dd_mm_yyyy_regex.test(date)) {
      const match = date.match(dd_mm_yyyy_regex);
      if (match) {
        day = parseInt(match[1], 10);
        month = parseInt(match[2], 10);
        year = parseInt(match[3], 10);
      }
    } else if (yyyy_mm_dd_regex.test(date)) {
      const match = date.match(yyyy_mm_dd_regex);
      if (match) {
        year = parseInt(match[1], 10);
        month = parseInt(match[2], 10);
        day = parseInt(match[3], 10);
      }
    } else {
      return null;
    }
    return year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
  }


  /**
   * Get the "Casino Day" from a given local date time.
   * The returned date is in the format 'dd/mm/YYYY'.
   * If the input is an ISO string representing a date, it will be converted to a Date object.
   * If the input is a string that can't be parsed as a date, the function will return it as is.
   *
   * @param localDatetime - The input local date time, as a Date object or an ISO string
   * @returns - The "Casino Day" as a string in the format 'dd/mm/YYYY', or the input string if it can't be parsed as a date
   */
  public getCasinoDayFromLocalDateTime(localDatetime: Date | string | null): Date | null {
    let date: Date;

    if (localDatetime == null) {
      return null;
    }

    // Check if localDatetime is a string
    if (typeof localDatetime === 'string') {
      date = new Date(localDatetime);

      // If the date is invalid, return the input as is
      if (isNaN(date.getTime())) {
        return null;
      }
    } else {
      date = localDatetime;
    }

    // Subtract 6 hours from the date
    date.setHours(date.getHours() - 6);
    return date;
  }

  public getStringCasinoDayFromLocalDateTime(localDatetime: Date | string | null): string {

    let date = this.getCasinoDayFromLocalDateTime(localDatetime)!;
    // Format the date as a string in 'dd/mm/YYYY' format
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const casinoDay = `${day}/${month}/${year}`;

    return casinoDay;
  }

  static async serviceAlreadyRegistred() {
    let registrations = await navigator.serviceWorker.getRegistrations();
    return registrations.find(registration => {
      console.log('Service worker already registred for :' + window.location.origin, registration.scope.startsWith(window.location.origin));
      registration.scope.startsWith(window.location.origin);
    }) ? true : false;
  }


  onlyBase64(s: string) {
    return s.replace("data:", "")
      .replace(/^.+,/, "");
  }

  /**
   * Convert Base 64 url to file
   * @param dataurl
   * @returns
   */
  base64toFile(dataurl: any, fileName: string): File {
    var fileName = `${fileName}.jpg`;
    // splits the dataurl into an array
    var arr = dataurl.split(','),
      // extracts the MIME type
      mime = arr[0].match(/:(.*?);/)[1],
      // decodes the Base64 string
      bstr = atob(arr[1]),
      n = bstr.length,
      // creates a Uint8Array from the decoded string
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    // creates and returns a new File object using the Uint8Array and the fileName
    return new File([u8arr], fileName);
  }

  fileToBase64(file: File) {
    const res = new Subject<string>();
    let reader = new FileReader();
    reader.onload = () => {
      res.next(reader.result as string);
      res.complete();
    };
    reader.readAsDataURL(file);
    return res.asObservable();
  }

  private sortByName(a: KeyValue<string, string>, b: KeyValue<string, string>) {
    // { sensitivity: 'base' } option ensures that the comparison is case and accent insensitive.
    return a.value.localeCompare(b.value, 'fr', { sensitivity: 'base' });
  }

  /**
   * Splits an array of items into two arrays based on the most used keys.
   *
   * @param {any[]} allItems - The array of items to be split.
   * @param {any[]} mostUsedKeys - The array of keys that determine the split.
   * @return {any[]} An array containing two arrays: the most used items and the rest of the items.
   */
  splitArray(allItems: any[], mostUsedKeys: any[]): any[] {
    const allItemsArray = allItems.map(([key, value]) => ({ key, value }));
    allItemsArray.sort(this.sortByName);

    const mostUsedItems = allItemsArray.filter(item => mostUsedKeys.includes(item.key));
    const otherItems = allItemsArray.filter(item => !mostUsedKeys.includes(item.key));

    return [mostUsedItems, otherItems];
  }

  /**
   * Capitalizes the first letter of each word in a given string.
   *
   * @param {string} str - The input string to be capitalized.
   * @return {string} The capitalized string.
   */
  public capitalize(str: string | null | undefined): string | null {
    return str ? str.split(/(\s|-)/).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('') : null;
  }

  // https://gist.github.com/ORESoftware/ba5d03f3e1826dc15d5ad2bcec37f7bf
  // https://jsfiddle.net/ascorbic/wn655txt/2/
  resizeImage(imageBase64Str: string, fileSizeInBytes: number): Observable<string> {
    if (fileSizeInBytes <= MAX_IMAGE_SIZE_IN_BYTES) {
      return of(this.onlyBase64(imageBase64Str));
    }
    let subject = new Subject<string>();
    // We create an img tag with the base64string to work on the image
    let img = new Image();
    img.src = imageBase64Str;
    img.onload = (ev) => {
      // We calculate the max height/width to be under the MAX_IMAGE_SIZE_IN_BYTES
      const resizeRation = Math.sqrt(MAX_IMAGE_SIZE_IN_BYTES / fileSizeInBytes);
      const MAX_WIDTH = Math.floor(img.width * resizeRation);
      const MAX_HEIGHT = Math.floor(img.height * resizeRation);
      // We create a canvas to resize and reduce a bit the image's quality
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      // We set the new image's size
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx!.drawImage(img, 0, 0, width, height);
      let b64Image = canvas.toDataURL('image/jpeg', 0.8);
      let calculatedSize = Math.floor((this.onlyBase64(b64Image).length * 6) / 8);
      if (calculatedSize > MAX_IMAGE_SIZE_IN_BYTES) {
        this.resizeImage(b64Image, calculatedSize).subscribe(b64Image => {
          subject.next(b64Image);
        })
      }
      else {
        subject.next(this.onlyBase64(b64Image));
      }
    }
    // We return an observable cause the "img.onLoad" function is async and we need the draw end
    return subject;
  }

  /**
   * Turn dataURl Into file
   */
  public dataURLtoFile(dataurl: string, filename: string) {
    let arr = dataurl.split(',');
    let mime = arr?.[0].match(/:(.*?);/)?.[1];
    let bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Formats the input string into a date pattern DD/MM/YYYY.
   *
   * @param {string} inputValue - The input string to be formatted.
   * @return {string} The formatted date string.
   */
  public formatToDatePattern(inputValue: string): string {
    if (!inputValue) {
      return '';
    }
    return inputValue.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '');
  }
}
