<ng-container *ngIf="!isLoadingVersions && !hasError; else loading">
  <table class="table">
    <thead *ngIf="versionsAppsList.length > 0" style="padding-bottom: 10px">
      <tr>
        <th class="table-header">
          {{ "VERSIONS.TABLE.HEADER.APP_NAME" | translate }} ({{versionsAppsList.length}})
        </th>
        <th class="table-header">
          {{ "VERSIONS.TABLE.HEADER.VERSION" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let version of versionsAppsList let i = index" class="table-row"
        [ngClass]="{'even-row': i % 2 == 0, 'odd-row': i % 2 != 0}">
        <td class="element-table text-align-end">{{ version.title }}</td>
        <td class="element-table ">{{ version.version }}</td>
      </tr>
      <!-- Show "No data" row if array is empty -->
      <tr *ngIf="versionsAppsList.length === 0">
        <td colspan="12">
          <div class="no-result-container">
            <mat-icon class="no-result-icon-format">search_off</mat-icon>
            <span class="no-result-message">{{ "PAGE.TABLE.NO_RESULT" | translate }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <table class="table">
    <thead *ngIf="versionsAppsList.length > 0" style="padding-bottom: 10px">
      <tr>
        <th class="table-header">
          {{ "VERSIONS.TABLE.HEADER.API_NAME" | translate }} ({{versionApiList.length}})
        </th>
        <th class="table-header">
          {{ "VERSIONS.TABLE.HEADER.VERSION" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let version of versionApiList let i = index" class="table-row"
        [ngClass]="{'even-row': i % 2 == 0, 'odd-row': i % 2 != 0}">
        <td class="element-table text-align-end">{{ version.title }}</td>
        <td class="element-table ">{{ version.version }}</td>
      </tr>
      <!-- Show "No data" row if array is empty -->
      <tr *ngIf="versionsAppsList.length === 0">
        <td colspan="12">
          <div class="no-result-container">
            <mat-icon class="no-result-icon-format">search_off</mat-icon>
            <span class="no-result-message">{{ "PAGE.TABLE.NO_RESULT" | translate }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="hasError">

</ng-container>

<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner color="primary" diameter="150" strokeWidth="3"></mat-spinner>
  </div>
</ng-template>