import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConfirmationBottomSheetComponent } from '../confirmation-bottom-sheet/confirmation-bottom-sheet.component'
import { ClientDraftService } from '../../services/client-draft.service';
import { IdScanJobService } from '../../services/id-scan-job.service';
import { UtilsService } from '../../services/utils.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Filter } from '../../services/firestore-repository-base.service';
import { MobileJobService } from '../../services/mobile-job.service';
import { BottomSheetCloseReason, CustomerExtendedSignatureSteps, IdScanSubSteps, SignatureSteps } from "../../enums/process-steps.enum";
import { SignatureJobService } from "../../services/signature-job.service";
import {
  LvaCreationJobService
} from "../../services/lva-creation-job.service";
import { LvaCreationJob } from "../../models/lva-creation-job.class";
import { CustomerExtendedSignatureJobService } from '../../services/customer-extended-signature-job.service';

@Component({
  selector: 'app-in-app-notifications',
  templateUrl: './in-app-notifications.component.html',
  styleUrls: ['./in-app-notifications.component.scss']
})
export class InAppNotificationsComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public isMobile: boolean = false;

  constructor(
    private clientDraftService: ClientDraftService,
    private idScanJobService: IdScanJobService,
    private mobileJobService: MobileJobService,
    private bottomSheet: MatBottomSheet,
    private utils: UtilsService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private signatureJobService: SignatureJobService,
    private customerExtendedSignatureJobService: CustomerExtendedSignatureJobService,
    private lvaCreationJobService: LvaCreationJobService,
  ) {
  }

  async ngOnInit() {

    this.isMobile = this.utils.isMobile();

    if (this.isMobile) {
      await this.checkForDocumentScanNotification(true);
      await this.checkForSignatureNotification(true);
      await this.checkForSignatureOnlyNotification(true);
      await this.checkForCustomerExtendedSignatureNotification(true);
      await this.checkForRenewIdScanScanNotification(true);
      await this.checkForRenewIdSignatureNotification(true);
      await this.checkForRedoSignatureNotification(true);
      await this.checkForLvaSignature(true);
    }
  }

  /**
   * Unsubscribe to all subscriptions
   */
  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Manage the document scan notification logic in client creation process
   */
  async checkForDocumentScanNotification(isInit: boolean) {

    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    // Client Draft Notification
    let subscriptionClientDraft = this.clientDraftService.list(
      {
        filters: [
          new Filter("currentStep.index", "==", 2),
          new Filter("currentStep.subIndex", "==", IdScanSubSteps.GrabYourPhone),
          new Filter("lastModifiedByEmail", "==", currentUserEmail)
        ],
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Bottom Sheet for Client Creation Process : checkForDocumentScanNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.CLIENT_CREATION_TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.SCAN_SUBTITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.SCAN_TEXT'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/pic-id-card.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let clientDraft = list.result[0];
            let newSubIndex = clientDraft.currentStep.subIndex;

            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              console.log('Bottom Sheet : Yes Button')
              newSubIndex = 2;
              await this.clientDraftService.partialUpdate(clientDraft.id!, {
                currentStep: { index: clientDraft.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/czam/client-creation-mobile?draftId=" + clientDraft.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              console.log('Bottom Sheet : No Button')
              newSubIndex = 0;
              await this.clientDraftService.partialUpdate(clientDraft.id!, {
                currentStep: { index: clientDraft.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscriptionClientDraft);
  }

  /**
   * Manage the document scan notification for id renew
   */
  async checkForRenewIdScanScanNotification(isInit: boolean) {

    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    // IdScanJob Notification
    let subscriptionIdScanJob = this.idScanJobService.list(
      {
        filters: [
          new Filter("currentStep.index", "==", 1),
          new Filter("currentStep.subIndex", "==", IdScanSubSteps.GrabYourPhone),
          new Filter("lastModifiedByEmail", "==", currentUserEmail)
        ],
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 30
      }
    ).subscribe({
      next: (list) => {

        // Desktop Wizard
        if (list.result.length > 0) {
          console.log('Launch Bottom Sheet for ID Renew Notification : checkForRenewIdScanScanNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.RENEW_ID_TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.RENEW_TITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.RENEW_SCAN_TEXT'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/pic-id-card.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let idScanJob = list.result[0];
            let newSubIndex = idScanJob.currentStep.subIndex;
            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newSubIndex = 2;
              await this.idScanJobService.partialUpdate(idScanJob.id!, {
                currentStep: { index: idScanJob.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/czam/id-scan-mobile?scanJobId=" + idScanJob.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newSubIndex = 0;
              await this.idScanJobService.partialUpdate(idScanJob.id!, {
                currentStep: { index: idScanJob.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscriptionIdScanJob);
  }

  /**
   * Manage the signature notification logic
   */
  async checkForSignatureNotification(isInit: boolean) {

    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    let filters = [
      new Filter("currentStep.index", "==", 4),
      new Filter("currentStep.subIndex", "==", SignatureSteps.GrabYourPhone),
      new Filter("lastModifiedByEmail", "==", currentUserEmail)
    ];

    let subscription = this.clientDraftService.list(
      {
        filters,
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Notif Bottom Sheet : checkForSignatureNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.CLIENT_CREATION_TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.SIGNATURE_SUBTITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.QUESTION_SIGNATURE'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/signature.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let clientDraft = list.result[0];
            let newSubIndex = clientDraft.currentStep.subIndex;
            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newSubIndex = 2;
              await this.clientDraftService.partialUpdate(clientDraft.id!, {
                currentStep: { index: clientDraft.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/czam/client-creation-mobile?draftId=" + clientDraft.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newSubIndex = 0;
              await this.clientDraftService.partialUpdate(clientDraft.id!, {
                currentStep: { index: clientDraft.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscription);
  }


  async checkForSignatureOnlyNotification(isInit: boolean) {
    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    let filters = [
      new Filter("currentStep.index", "==", 1),
      new Filter("currentStep.subIndex", "==", SignatureSteps.GrabYourPhone),
      new Filter("lastModifiedByEmail", "==", currentUserEmail)
    ];

    let subscription = this.signatureJobService.list(
      {
        filters,
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Notif Bottom Sheet : checkForSignatureNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.TAKE_SIGNATURE_TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.SIGNATURE_SUBTITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.QUESTION_SIGNATURE'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/signature.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let signatureJob = list.result[0];
            let newSubIndex = signatureJob.currentStep.subIndex;
            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newSubIndex = 3;
              await this.signatureJobService.partialUpdate(signatureJob.id!, {
                currentStep: { index: signatureJob.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/czam/mobile-signature?draftId=" + signatureJob.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newSubIndex = 0;
              await this.signatureJobService.partialUpdate(signatureJob.id!, {
                currentStep: { index: signatureJob.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  async checkForCustomerExtendedSignatureNotification(isInit: boolean) {
    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    let filters = [
      new Filter("currentStep.index", "==", 1),
      new Filter("currentStep.subIndex", "==", CustomerExtendedSignatureSteps.GrabYourPhone),
      new Filter("lastModifiedByEmail", "==", currentUserEmail)
    ];

    let subscription = this.customerExtendedSignatureJobService.list(
      {
        filters,
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Notif Bottom Sheet : checkForCustomerExtendedSignatureNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.CUSTOMER_EXTENDED.CONSENT.TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.CUSTOMER_EXTENDED.CONSENT.SUBTITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.CUSTOMER_EXTENDED.CONSENT.TEXT'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/consent.svg'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let signatureJob = list.result[0];
            let newSubIndex = signatureJob.currentStep.subIndex;
            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newSubIndex = 2;
              await this.customerExtendedSignatureJobService.partialUpdate(signatureJob.id!, {
                currentStep: { index: signatureJob.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/client-hub/mobile-consent?jobId=" + signatureJob.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newSubIndex = 0;
              await this.customerExtendedSignatureJobService.partialUpdate(signatureJob.id!, {
                currentStep: { index: signatureJob.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscription);
  }


  /**
   * Check if a signature for the LVA process is required
   */
  async checkForLvaSignature(isInit: boolean) {
    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;
    let filters = [
      new Filter("currentStep.index", "==", 3),
      new Filter("currentStep.subIndex", "==", SignatureSteps.GrabYourPhone),
      new Filter("lastModifiedByEmail", "==", currentUserEmail)
    ];

    let subscription = this.lvaCreationJobService.list(
      {
        filters,
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Notif Bottom Sheet : checkForSignatureNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.LVA.TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.LVA.SIGNATURE_SUBTITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.LVA.QUESTION_SIGNATURE'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/signature.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let lvaCreationJob: LvaCreationJob = list.result[0];
            let newSubIndex = lvaCreationJob.currentStep.subIndex;
            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newSubIndex = 3;
              await this.lvaCreationJobService.partialUpdate(lvaCreationJob.id!, {
                currentStep: { index: lvaCreationJob.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/casino-restrictions-lva/mobile-signature?lvaCreationId=" + lvaCreationJob.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newSubIndex = 0;
              await this.lvaCreationJobService.partialUpdate(lvaCreationJob.id!, {
                currentStep: { index: lvaCreationJob.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Manage the signature notification for renew id
   */
  async checkForRenewIdSignatureNotification(isInit: boolean) {

    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    let filters = [
      new Filter("currentStep.index", "==", 3),
      new Filter("currentStep.subIndex", "==", SignatureSteps.GrabYourPhone),
      new Filter("lastModifiedByEmail", "==", currentUserEmail)
    ];

    let subscription = this.idScanJobService.list(
      {
        filters,
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Notif Bottom Sheet : checkForRenewIdSignatureNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: this.translateService.instant('BOTTOM_SHEET.RENEW_ID_TITLE'),
              subtitle: this.translateService.instant('BOTTOM_SHEET.SIGNATURE_SUBTITLE'),
              text: this.translateService.instant('BOTTOM_SHEET.QUESTION_SIGNATURE'),
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/signature.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let idScanJob = list.result[0];
            let newSubIndex = idScanJob.currentStep.subIndex;
            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newSubIndex = 2;
              await this.idScanJobService.partialUpdate(idScanJob.id!, {
                currentStep: { index: idScanJob.currentStep.index, subIndex: newSubIndex }
              })
              window.location.href = "/czam/id-scan-mobile?scanJobId=" + idScanJob.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newSubIndex = 0;
              await this.idScanJobService.partialUpdate(idScanJob.id!, {
                currentStep: { index: idScanJob.currentStep.index, subIndex: newSubIndex }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscription);
  }


  /**
   * Manage the signature notification for renew id
   */
  async checkForRedoSignatureNotification(isInit: boolean) {

    let currentUserEmail = (await firstValueFrom(this.authenticationService.user))?.email;

    let filters = [
      new Filter("currentStep.index", "==", 1),
      new Filter("currentStep.subIndex", "==", 0),
      new Filter("lastModifiedByEmail", "==", currentUserEmail)
    ];

    let subscription = this.mobileJobService.list(
      {
        filters,
        orderBy: [],
        startAfter: null,
        endAt: null,
        limit: 50
      }
    ).subscribe({
      next: (list) => {
        if (list.result.length > 0) {
          console.log('Launch Notif Bottom Sheet : checkForRenewIdSignatureNotification')

          this.bottomSheet.open(ConfirmationBottomSheetComponent, {
            disableClose: true,
            data: {
              title: 'Signature',
              subtitle: 'Signature à refaire',
              text: 'Voulez-vous effectuer la signature du client?',
              yesButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_YES'),
              noButtonCaption: this.translateService.instant('BOTTOM_SHEET.BUTTON_NO'),
              imgUrl: 'assets/lib-common/images/signature.png'
            }
          }).afterDismissed().subscribe(async closeReason => {
            let mobileJob = list.result[0];
            let newIndex = mobileJob?.currentStep?.index;

            if (closeReason == BottomSheetCloseReason.Canceled) {
              return;
            }
            // User click the YES button
            else if (closeReason == BottomSheetCloseReason.Yes) {
              newIndex = 2;
              await this.mobileJobService.partialUpdate(mobileJob.id!, {
                currentStep: { index: newIndex, subIndex: 0 }
              })
              window.location.href = "/czam/mobile-job?mobileJobId=" + mobileJob.id;
            }
            // User click the NO button
            else if (closeReason == BottomSheetCloseReason.No) {
              newIndex = 0;
              await this.mobileJobService.partialUpdate(mobileJob.id!, {
                currentStep: { index: newIndex, subIndex: 0 }
              })
            }
          })
        } else if (!isInit) {
          this.bottomSheet.dismiss(BottomSheetCloseReason.Canceled)
        }
      }
    });
    this.subscriptions.push(subscription);
  }
}
