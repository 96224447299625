import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { ICzamSystemInfoDto } from "./interfaces/czam-system-info-dto";

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private baseUrl = environment.czamApiUrl + '/system';
  private static systemInfo: ICzamSystemInfoDto | null = null;

  constructor(private http: HttpClient) {
  }

  getParameters(): Observable<ICzamSystemInfoDto> {
    if (SystemService.systemInfo) {
      return of(SystemService.systemInfo)
    }
    return this.http.get<ICzamSystemInfoDto>(`${this.baseUrl}`).pipe(map(res => {
      SystemService.systemInfo = res;
      return res;
    }));
  }


  getVersionFromSystem(destination: string): Observable<string> {
    return this.http.get<ICzamSystemInfoDto>('/' + destination + '/system').pipe(map(res => {
      return res.version
    }));
  }

}
