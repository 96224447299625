import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "lib-common";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { VersionPageComponent } from './pages/version-page/version-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AccessDeniedPageComponent } from "./pages/access-denied-page/access-denied-page.component";
import { HomePageOfflineComponent } from "./pages/home-page-offline/home-page-offline.component";
import { VersionsPageComponent } from "./pages/versions-page/versions-page.component";

const routes: Routes = [
  { path: 'dashboard', component: HomePageComponent, canActivate: [AuthGuardService] },
  { path: 'dashboard-offline', component: HomePageOfflineComponent, data: { offline: true } },
  { path: 'access-denied', component: AccessDeniedPageComponent, canActivate: [AuthGuardService] },
  { path: 'version', component: VersionPageComponent },
  { path: 'versions', component: VersionsPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
