import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, catchError, of, } from "rxjs";
import { CasinoLoyaltyApiService, CasinoRestrictionsApiService, SwiftSalesService, CasinoInjApiService, OcmService, SystemService, WalletApiService, AsosApiService, NotificationCenterService } from "lib-common";

@Component({
  selector: 'app-versions-page',
  templateUrl: './versions-page.component.html',
  styleUrls: ['./versions-page.component.scss']
})
export class VersionsPageComponent implements OnInit {


  isLoadingVersions = true;
  hasError = false;
  versionsAppsList: { title: string, version: string }[] = [];
  versionApiList: { title: string, version: string }[] = [];


  constructor(private systemService: SystemService, private ocmService: OcmService,
    private loyaltyService: CasinoLoyaltyApiService, private casinoInjApiService: CasinoInjApiService,
    private swiftSaleService: SwiftSalesService, private casinoRestrictionsApiService: CasinoRestrictionsApiService,
    private casinoAsosApiService: AsosApiService, private walletApiService: WalletApiService, private notificationApiService: NotificationCenterService) {
  }

  async ngOnInit() {

    const safe = <T>(obs: Observable<T>) => obs.pipe(catchError(() => of(undefined)));

    let observable = forkJoin({
      adminVersion: safe(this.systemService.getVersionFromSystem('casino-admin')),
      asosVersion: safe(this.systemService.getVersionFromSystem('asos')),
      homeVersion: safe(this.systemService.getVersionFromSystem('home')),
      czamVersion: safe(this.systemService.getVersionFromSystem('czam')),
      clientHubCasinoVersion: safe(this.systemService.getVersionFromSystem('client-hub')),
      jrVersion: safe(this.systemService.getVersionFromSystem('casino-jr')),
      injVersion: safe(this.systemService.getVersionFromSystem('casino-restrictions-inj')),
      gelavVersion: safe(this.systemService.getVersionFromSystem('casino-restrictions-gelav')),
      anprVersion: safe(this.systemService.getVersionFromSystem('casino-restrictions-anpr')),
      apVersion: safe(this.systemService.getVersionFromSystem('casino-restrictions-ap')),
      lvaVersion: safe(this.systemService.getVersionFromSystem('casino-restrictions-lva')),
      guestRegardsVersion: safe(this.systemService.getVersionFromSystem('casino-guest-regards')),
      clienthubVersion: safe(this.systemService.getVersionFromSystem('client-hub')),
      reservationHotelVersion: safe(this.systemService.getVersionFromSystem('hotel-reservation')),
      notificationVersion: safe(this.systemService.getVersionFromSystem('notification-center')),


      czamApi: safe(this.ocmService.getParameters()),
      ocmApi: safe(this.systemService.getParameters()),
      restrictionInjApi: safe(this.casinoInjApiService.getParameters()),
      swiftSaleApi: safe(this.swiftSaleService.getParameters()),
      loyaltyApi: safe(this.loyaltyService.getParameters()),
      walletApi: safe(this.walletApiService.getParameters()),
      asosApi: safe(this.casinoAsosApiService.getParameters()),
      restrictionsApi: safe(this.casinoRestrictionsApiService.getParameters()),
      notificationApi: safe(this.notificationApiService.getParameters())
    })

    observable.subscribe({
      next: (versions) => {
        this.versionsAppsList = [
          { title: 'app-casino-admin', version: versions.adminVersion ?? 'N/A' },
          { title: 'app-casino-asos', version: versions.asosVersion ?? 'N/A' },
          { title: 'app-home', version: versions.homeVersion ?? 'N/A' },
          { title: 'app-czam', version: versions.czamVersion ?? 'N/A' },
          { title: 'app-casino-client-hub', version: versions.clientHubCasinoVersion ?? 'N/A' },
          { title: 'app-casino-jr', version: versions.jrVersion ?? 'N/A' },
          { title: 'app-casino-restrictions-inj', version: versions.injVersion ?? 'N/A' },
          { title: 'app-casino-restrictions-gelav', version: versions.gelavVersion ?? 'N/A' },
          { title: 'app-casino-restrictions-anpr', version: versions.anprVersion ?? 'N/A' },
          { title: 'app-casino-restrictions-ap', version: versions.apVersion ?? 'N/A' },
          { title: 'app-casino-restrictions-lva', version: versions.lvaVersion ?? 'N/A' },
          { title: 'app-casino-guest-regards', version: versions.guestRegardsVersion ?? 'N/A' },
          { title: 'app-hotel-client-hub', version: versions.clienthubVersion ?? 'N/A' },
          { title: 'app-hotel-reservation', version: versions.reservationHotelVersion ?? 'N/A' },
          { title: 'app-notification-center', version: versions.notificationVersion ?? 'N/A' }
        ];

        this.versionApiList = [
          { title: 'czam-api', version: versions.czamApi?.version ?? 'N/A' },
          { title: 'ocm-connect-api', version: versions.ocmApi?.version ?? 'N/A' },
          { title: 'casino-inj-api', version: versions.restrictionInjApi?.version ?? 'N/A' },
          { title: 'casino-loyalty-api', version: versions.loyaltyApi?.version ?? 'N/A' },
          { title: 'swiftsale-api', version: versions.swiftSaleApi?.version ?? 'N/A' },
          { title: 'wallet-api', version: versions.walletApi?.version ?? 'N/A' },
          { title: 'casino-asos-api', version: versions.asosApi?.version ?? 'N/A' },
          { title: 'casino-restrictions-api', version: versions.restrictionsApi?.version ?? 'N/A' },
          { title: 'notification-center-api', version: versions.notificationApi?.version ?? 'N/A' },
        ];

        this.isLoadingVersions = false;
      },
      error: () => {
        this.isLoadingVersions = false;
        this.hasError = true;
      },
      complete: () => {

      }
    });
  }

}
