<div class="booking-card" *ngIf="booking">
  <div class="picture">
    <img *ngIf="hotel?.picture" src="{{ hotel?.picture }}" alt="hotel picture">
    <img *ngIf="!hotel?.picture" src="assets/images/image-not-available.png" alt="hotel picture">
    <div *ngIf="booking.stayStatus" class="status">{{ booking.giftBox ? ('BOOKING.GIFT_BOX' | translate) :
      booking.stayStatus }}</div>
  </div>
  <div class="details">
    <h4 *ngIf="hotel">
      {{ hotel.name }}
      {{ stars }}
    </h4>
    <h5 *ngIf="hotel">{{ hotel.city }}</h5>
    <div class="reservation">
      <span class="mat-body-2">{{ 'BOOKING.RESERVATION_NUMBER' | translate }}</span>
      <span class="mat-body-1">{{ booking.confirmationNumber }}</span>
    </div>
    <p><strong>{{ booking.arrivalDate }}</strong> <span class="date-separator"><mat-icon
          class="chevron">chevron_right</mat-icon></span> <strong>{{ booking.departureDate }}</strong></p>
    <p><strong>{{ booking.numberOfNights }} {{ 'BOOKING.NIGHTS' | translate }}</strong> {{ 'BOOKING.AT' | translate }}
      <strong>{{ booking.rateAmountPerNight }}€/{{ 'BOOKING.NIGHTS_SPENT' | translate }}</strong></p>
    <p><strong>{{ booking.numberOfOccupants }} {{ 'BOOKING.PERSONS' | translate }}</strong> {{ 'BOOKING.IN' | translate
      }} <strong>{{ booking.roomType }}</strong></p>
  </div>
  <span class="stay-status" *ngIf="booking.staySatisfactionLevel">
    <mat-icon>
      {{ parseInt(booking.staySatisfactionLevel) > 3 ? 'sentiment_very_satisfied':
      parseInt(booking.staySatisfactionLevel) < 3 ? 'sentiment_dissatisfied' : 'sentiment_satisfied' }} </mat-icon>
        {{ booking.staySatisfactionLevel }} / 5
  </span>
</div>