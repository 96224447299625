import { Injectable } from '@angular/core';
import { ICasinoLoyaltyCustomerDto } from "./dtos/casino-loyalty-customer-dto";
import { catchError, map, Observable, of, retry } from 'rxjs';
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ICasinoLoyaltyCustomerSearchDto } from './dtos/casino-loyalty-customer-search-dto';
import { ICasinoLoyaltyCustomerResultDto } from './dtos/casino-loyalty-customer-result-dto';
import { ErrorService } from '../error.service';
import { ICasinoLoyaltyCustomerBasicDataDto } from './dtos/casino-loyalty-customer-basic-data-dto';
import { TranslateService } from '@ngx-translate/core';
import { ICasinoLoyaltyVdi } from "./dtos/casino-loyalty-vdi";
import { ICasinoLoyaltyCustomerSponsorshipDataDto } from './dtos/casino-loyalty-customer-sponsorship-data-dto';

const ENTITY_REQUEST_PER_SEARCH = 30;

@Injectable({
  providedIn: 'root'
})
export class CasinoLoyaltyCustomerService {

  private urlBase = environment.casinoLoyaltyApiUrl + '/customers/';

  constructor(private http: HttpClient, private errorService: ErrorService, private translateService: TranslateService) { }

  search(page: number = 1, casinoLoyaltyCustomerSearch: ICasinoLoyaltyCustomerSearchDto): Observable<ICasinoLoyaltyCustomerResultDto | null> {
    if (casinoLoyaltyCustomerSearch.cardNumber === ''
      && casinoLoyaltyCustomerSearch.lastName === ''
      && casinoLoyaltyCustomerSearch.firstName === ''
      && casinoLoyaltyCustomerSearch.birthDate === null
    ) {
      return of(null);
    }

    const params = this.buildHttpParamsForSearch(casinoLoyaltyCustomerSearch, page);

    return this.http.get<ICasinoLoyaltyCustomerResultDto>(this.urlBase + 'search', { params }).pipe(map((data) => {
      if (data.result && data.result.length) {
        data.result.map((result) => {
          result.birthdate = result.birthdate !== null ? new Date(result.birthdate) : null;
        });
      }
      return data;
    }), catchError(err => {
      this.errorService.error(err);
      return of(null);
    }));
  }

  private buildHttpParamsForSearch(casinoLoyaltyCustomerSearch: ICasinoLoyaltyCustomerSearchDto, page: number): HttpParams {
    let params = new HttpParams().set('page', page).set('count', ENTITY_REQUEST_PER_SEARCH);

    if (casinoLoyaltyCustomerSearch.birthDate && casinoLoyaltyCustomerSearch.birthDate !== '') {
      params = params.set('birthdate', casinoLoyaltyCustomerSearch.birthDate);
    }

    if (casinoLoyaltyCustomerSearch.firstName && casinoLoyaltyCustomerSearch.firstName !== '') {
      params = params.set('firstnames', casinoLoyaltyCustomerSearch.firstName.trim());
    }

    if (casinoLoyaltyCustomerSearch.lastName && casinoLoyaltyCustomerSearch.lastName !== '') {
      params = params.set('birthname', casinoLoyaltyCustomerSearch.lastName.trim());
    }

    if (casinoLoyaltyCustomerSearch.cardNumber && casinoLoyaltyCustomerSearch.cardNumber !== '') {
      params = params.set('card_number', casinoLoyaltyCustomerSearch.cardNumber.trim());
    }

    if (casinoLoyaltyCustomerSearch.level && casinoLoyaltyCustomerSearch.level !== '') {
      params = params.set('status', casinoLoyaltyCustomerSearch.level);
    }

    return params;
  }

  getLoyaltyBySesameId(sesameId: number) {
    const url = environment.casinoLoyaltyApiUrl + '/customers/' + `${sesameId}` + '/vdi-loyalty-details';
    return this.http.get<ICasinoLoyaltyVdi>(url);
  }

  get(customerKey: number, allowRetry: boolean = false, showError = true): Observable<ICasinoLoyaltyCustomerDto | null> {
    return this.http.get<ICasinoLoyaltyCustomerDto>(this.urlBase + customerKey).pipe(
      map((data) => {
        //data.birthdate = data.birthdate !== null ? new Date(data.birthdate) : null;
        return data;
      }),
      retry({ count: allowRetry ? 3 : 0, delay: allowRetry ? 2000 : 0 }),
      catchError(err => {
        if (showError) {
          this.errorService.error(this.translateService.instant('ERROR.ERROR_MAXXING') + err.error.error);
        }
        return of(null);
      }),

    );
  }

  editAddress(ciamId: string, data: any): Observable<ICasinoLoyaltyCustomerDto | null> {
    return this.http.put<ICasinoLoyaltyCustomerDto>(this.urlBase + ciamId + '/information', data).pipe(
      map((customer) => {
        //customer.birthdate = customer.birthdate !== null ? new Date(customer.birthdate) : null;
        return customer;
      }),
      catchError(err => {
        this.errorService.error(err);
        return of(null);
      })
    );
  }

  editContactDetails(ciamId: string, data: any): Observable<ICasinoLoyaltyCustomerDto | null> {
    return this.http.put<ICasinoLoyaltyCustomerDto>(this.urlBase + ciamId + '/information', data).pipe(
      map((customer) => {
        //customer.birthdate = customer.birthdate !== null ? new Date(customer.birthdate) : null;
        return customer;
      }),
      catchError(err => {
        this.errorService.error(err);
        return of(null);
      })
    );
  }

  mergeTwoCustomer(primaryCustomerKey: number, secondaryCustomerKey: number): Observable<ICasinoLoyaltyCustomerDto> {
    return this.http.put<ICasinoLoyaltyCustomerDto>(this.urlBase + primaryCustomerKey + '/merge/' + secondaryCustomerKey, {});
  }

  unmergeTwoCustomer(primaryCustomerKey: number, secondaryCustomerKey: number): Observable<ICasinoLoyaltyCustomerDto> {
    return this.http.put<ICasinoLoyaltyCustomerDto>(this.urlBase + primaryCustomerKey + '/unmerge/' + secondaryCustomerKey, {});
  }

  /**
   * Returns the basic data for the given client list
   * @param currentCasino This is used to add the information "Is client in the casino"
   * @param customerKeys
   */
  getBasicData(currentCasino: string, customerKeys?: string[]): Observable<ICasinoLoyaltyCustomerBasicDataDto[]> {
    const url = this.urlBase + 'basicData';

    // Construct the query parameters
    let params = new HttpParams();

    if (customerKeys) {
      // Add each customer key to the 'customerKeys' parameter
      for (const key of customerKeys) {
        params = params.append('customerKeys', key);
      }
    }

    // Set the 'casino' parameter
    params = params.set('casino', currentCasino);

    // Make the HTTP request with the query parameters
    return this.http.get<ICasinoLoyaltyCustomerBasicDataDto[]>(url, { params });
  }

  updateLuckyDates(customerKey: number, data: any) {
    return this.http.put(this.urlBase + customerKey + '/luckyDates', data);
  }

  getCustomerSponsorees(customerKey: number): Observable<number[]> {
    return this.http.get<number[]>(`${this.urlBase}${customerKey}/sponsoree`);
  }

  addSponsor(customerKey: number, sponsorCustomerKey: number) {
    return this.http.put(`${this.urlBase}${customerKey}/sponsor`, { sponsorCustomerKey: sponsorCustomerKey.toString() });
  }

  getCustomerSponsorshipData(customerKey: number): Observable<ICasinoLoyaltyCustomerSponsorshipDataDto> {
    return this.http.get<ICasinoLoyaltyCustomerSponsorshipDataDto>(`${this.urlBase}${customerKey}/sponsorshipData`);
  }

  getCustomerMaxSponsorship(customerKey: number): Observable<number> {
    return of(10);
    return this.http.get<number>(`${this.urlBase}${customerKey}/maxSponsorship`);
  }
}
