import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { CasinoService } from '../casino.service';
import { Observable, map, of } from 'rxjs';
import { IMagneticCodeResult } from '../../interfaces/magnetic-code-result.interface';
import { IOcmSystemInfoDto } from './interfaces/ocm-system-info-dto';

@Injectable({
  providedIn: 'root'
})
export class OcmService {
  private baseUrl = environment.ocmConnectApiUrl;
  private static systemInfo: IOcmSystemInfoDto | null = null;

  constructor(private http: HttpClient, private casinoService: CasinoService) { }

  getNewMagneticCode(playerId: number): Observable<IMagneticCodeResult> {
    return this.http.post<IMagneticCodeResult>(`${this.baseUrl}/casinos/${this.casinoService.getCasinoCodeSync()}/players/${playerId}/card`, {

    })
  }

  getParameters(): Observable<IOcmSystemInfoDto> {
    if (OcmService.systemInfo) {
      return of(OcmService.systemInfo)
    }
    return this.http.get<IOcmSystemInfoDto>(`${this.baseUrl}/system`).pipe(map(res => {
      OcmService.systemInfo = res;
      return res;
    }));
  }
}
