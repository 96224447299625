import { environment } from "../../environments/environment";
import { map, Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICasinoInjApi } from "./interfaces/ICasinoInjApi";

@Injectable({
  providedIn: 'root'
})
export class CasinoInjApiService {
  private baseUrl = environment.casinoInjApiUrl;
  private static systemInfo: ICasinoInjApi | null = null;

  constructor(private http: HttpClient) { }

  getParameters(): Observable<ICasinoInjApi> {
    if (CasinoInjApiService.systemInfo) {
      return of(CasinoInjApiService.systemInfo)
    }
    return this.http.get<ICasinoInjApi>(`${this.baseUrl}/system`).pipe(map((res: any) => {
      let result: ICasinoInjApi = {
        commitHash: res.version.commit,
        version: res.version.tag
      }
      CasinoInjApiService.systemInfo = result
      return result;
    }));
  }
}
