import { Injectable } from '@angular/core';
import { map } from "rxjs";
import { IWalletApi } from "./interfaces/IWalletApi";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WalletApiService {

  constructor(public http: HttpClient) { }

  private urlBase = environment.walletApiUrl;

  getParameters() {
    return this.http.get( this.urlBase + '/system').pipe(map((res: any) => {
        let result: IWalletApi = {
          commitHash: res.version.commit,
          version: res.version.tag
        };

        return result;
      })
    )
  }
}
