import { FirestoreBaseEntity } from "../services/firestore-repository-base.service";
import { JobStep } from "./job-step.class";

export class CustomerExtendedSignatureJob extends FirestoreBaseEntity {
  currentStep = new JobStep();
  signatureBase64?: string | null;
  ciamId: string = '';
  urlSignature?: string;
  stepsDone: [] = [];

  emailConsent?: boolean;
  smsConsent?: boolean;
  postalConsent?: boolean;
  portalConsent?: boolean;
}
